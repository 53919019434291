import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { useAuth0 } from "@auth0/auth0-react";
import env from "../env";
import moment from "moment";
import Alert from "react-bootstrap/esm/Alert";

const TIER_CONFIGS = {
  BASE: { name: "Basic", cpu: "2 vCPUs", ram: "1GB RAM", price: 0 },
  TIER1: { name: "Tier 1", cpu: "2 vCPUs", ram: "2GB RAM", price: 4 },
  TIER2: { name: "Tier 2", cpu: "2 vCPUs", ram: "4GB RAM", price: 8 }
};

export default function Resources(props) {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null);
  const [nextTierChangeDate, setNextTierChangeDate] = useState(null);

  // Ensure we have a valid initial tier
  const getCurrentTier = () => {
    const serverTier = props.user.subscription?.serverTier || "BASE";
    return TIER_CONFIGS[serverTier] ? serverTier : "BASE";
  };

  const [storageQuantity, setStorageQuantity] = useState(props.user.storage?.quantity || 0);
  const [selectedTier, setSelectedTier] = useState(getCurrentTier());
  const [purchaseButtonDisabled, setPurchaseButtonDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Update selectedTier when props.user changes
  useEffect(() => {
    setSelectedTier(getCurrentTier());
  }, [props.user]);

  // Calculate current costs
  const basePlanCost =
    props.user.subscription.plan.name === "BASIC" ? 4 : props.user.subscription.plan.name === "PRO" ? 8 : 12;
  const currentStorageCost = (props.user.storage?.quantity || 0) * 4;
  const currentTierCost = TIER_CONFIGS[getCurrentTier()].price;
  const currentTotalCost = basePlanCost + currentStorageCost + currentTierCost;

  // Calculate new costs based on selections
  const newStorageCost = props.user.storage?.quantity * 4 + storageQuantity * 4;
  const newTierCost = TIER_CONFIGS[selectedTier]?.price || 0;
  const newTotalCost = basePlanCost + newStorageCost + newTierCost;

  // Calculate if there are any changes
  const hasChanges = storageQuantity > 0 || selectedTier !== getCurrentTier();

  const handleStorageChange = (e) => {
    const newQuantity = parseInt(e.target.value);
    setStorageQuantity(newQuantity);
    setPurchaseButtonDisabled(newQuantity === props.user.storage?.quantity && selectedTier === getCurrentTier());
  };

  const handleTierChange = (e) => {
    const newTier = e.target.value;
    setSelectedTier(newTier);
    setPurchaseButtonDisabled(storageQuantity === props.user.storage?.quantity && newTier === getCurrentTier());
  };

  const updateResources = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);
    setNextTierChangeDate(null);

    try {
      const accessToken = await getAccessTokenSilently({ audience: env.AUD });

      // Prepare request body based on what changed
      const requestBody = {};

      if (storageQuantity !== props.user.storage?.quantity) {
        // Calculate the difference in storage quantity
        requestBody.quantity = storageQuantity - (props.user.storage?.quantity || 0);
      }

      if (selectedTier !== getCurrentTier()) {
        requestBody.tier_level = selectedTier;
      }

      const response = await fetch(env.API_URL + `api/storage`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.nextChangeDate) {
          setNextTierChangeDate(moment(errorData.nextChangeDate));
        }
        throw new Error(errorData.error || "Failed to update resources");
      }

      // Update local state on success
      props.user.storage = {
        ...props.user.storage,
        quantity: storageQuantity,
        lastUpdated: moment()
      };
      props.user.subscription.serverTier = selectedTier;

      setPurchaseButtonDisabled(true);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const recentChange = props.user.storage?.lastUpdated && moment().diff(props.user.storage.lastUpdated, "hours") < 6;

  // Update this function to check if tier changes are allowed
  const isTierChangeAllowed = () => {
    if (!props.user.subscription?.tierChangeTimestamp) return true;
    const nextChangeDate = moment(props.user.subscription.tierChangeTimestamp).add(30, "days");
    return moment().isAfter(nextChangeDate);
  };

  // Calculate base storage based on plan
  const getBaseStorage = () => {
    switch (props.user.subscription.plan.name) {
      case "BASIC":
        return 5;
      case "PRO":
        return 10;
      case "ELITE":
        return 20;
      default:
        return 5;
    }
  };

  return (
    <Row>
      <Col></Col>
      <Col xs={6} md={4}>
        <h3>Server Resources</h3>
        <br />

        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Resource</th>
              <th>Current Configuration</th>
              <th>Monthly Cost</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Base Plan</td>
              <td>{props.user.subscription.plan.name}</td>
              <td>${basePlanCost}/mo</td>
            </tr>
            <tr>
              <td>Performance Tier</td>
              <td>
                {TIER_CONFIGS[getCurrentTier()].name} - {TIER_CONFIGS[getCurrentTier()].cpu},{" "}
                {TIER_CONFIGS[getCurrentTier()].ram}
              </td>
              <td>${currentTierCost}/mo</td>
            </tr>
            <tr>
              <td>Plan Storage</td>
              <td>{getBaseStorage()}GB</td>
              <td>Included</td>
            </tr>
            <tr>
              <td>Additional Storage</td>
              <td>{props.user.storage?.quantity || 0}x10GB</td>
              <td>${currentStorageCost}/mo</td>
            </tr>
            <tr className="table-active font-weight-bold">
              <td colSpan="2">Total Monthly Cost</td>
              <td>${currentTotalCost}/mo</td>
            </tr>
          </tbody>
        </Table>

        <Card className="mt-4">
          <Card.Header>
            <b>Modify Resources</b>
          </Card.Header>
          <Card.Body>
            <Form>
              {error && <Alert variant="danger">{error}</Alert>}

              <Form.Group>
                <Form.Label>Performance Tier</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedTier}
                  onChange={handleTierChange}
                  disabled={!isTierChangeAllowed()}
                >
                  <option value="BASE">Basic - 2 vCPU, 1GB RAM (+$0/mo)</option>
                  <option value="TIER1">Tier 1 - 2 vCPU, 2GB RAM (+$4/mo)</option>
                  <option value="TIER2">Tier 2 - 2 vCPU, 4GB RAM (+$8/mo)</option>
                </Form.Control>
                {(selectedTier !== getCurrentTier() || !isTierChangeAllowed()) && (
                  <Form.Text className="text-muted">
                    {props.user.subscription?.tierChangeTimestamp ? (
                      <>
                        You cannot change tiers until{" "}
                        {moment(props.user.subscription.tierChangeTimestamp).add(30, "days").format("MMMM D, YYYY")}
                      </>
                    ) : (
                      "Tier changes are limited to once every 30 days."
                    )}
                  </Form.Text>
                )}
              </Form.Group>

              <Form.Group>
                <Form.Label>Storage Quantity (10GB increments)</Form.Label>
                <Form.Control as="select" value={storageQuantity} onChange={handleStorageChange}>
                  {[...Array(6)].map((_, i) => (
                    <option key={i} value={i}>
                      {i * 10}GB (+${i * 4}/mo)
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              {hasChanges && <Alert variant="warning">New Monthly Total: ${newTotalCost}/mo</Alert>}

              {recentChange && <Alert variant="warning">Storage can only be modified once every six hours.</Alert>}

              <Button
                variant="success"
                onClick={updateResources}
                disabled={!hasChanges || recentChange || isSubmitting}
              >
                {isSubmitting ? (
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                ) : (
                  "Update Resources"
                )}
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Col>
      <Col></Col>
    </Row>
  );
}
