import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import InputGroup from "react-bootstrap/InputGroup";
import slugify from "slugify";
import env from "../env";
import FoundryVersionPickerComponent from "./foundryVersionPickerComponent";
import { useAuth0 } from "@auth0/auth0-react";
import { Redirect } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

const placeholder = "<PLACEHOLDER>";

export default function NewServerForm(props) {
  const [foundryVersion, setFoundryVersion] = useState("v12");
  const [experimentalEnabled, setExperimentalEnabled] = useState(false);
  const [serverName, setServerName] = useState(placeholder);
  const [region, setRegion] = useState("us-east-2");
  const [formValidationErrors, setFormValidationErrors] = useState(false);
  const [formValidationMessage, setFormValidationMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [redirectToStack, setRedirectToStack] = useState(false);
  const [showExperimentalModal, setShowExperimentalModal] = useState(false);
  const [pendingExperimentalState, setPendingExperimentalState] = useState(false);

  const cfnPattern = "^[a-zA-Z][-a-zA-Z0-9]*";
  const cfnRegex = RegExp(cfnPattern);

  const handleNewStackSubmit = async (e) => {
    e.preventDefault();

    if (!cfnRegex.test(serverName)) {
      setFormValidationErrors(true);
      setFormValidationMessage(`Must start with a letter and match the pattern ${cfnPattern}.`);

      return;
    }

    if (serverName.length < 4 || serverName.length > 30) {
      setFormValidationErrors(true);
      setFormValidationMessage(`Stack names must be between 4 and 30 characters.`);

      return;
    }

    if (serverName === placeholder) {
      setFormValidationErrors(true);
      setFormValidationMessage(`Please enter a server name.`);

      return;
    }

    setSubmitted(true);
    const accessToken = await getAccessTokenSilently({ audience: env.AUD });
    const response = await fetch(env.API_URL + "api/servers", {
      audience: env.AUD,
      method: "POST",
      body: JSON.stringify({
        foundryVersion: foundryVersion,
        serverName: serverName,
        region: region
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      }
    });

    if (!response.ok) {
      if (response.status === 409) {
        setFormValidationErrors(true);
        setFormValidationMessage(`Server name ${serverName} is already in use.`);
      } else {
        setFormValidationErrors(true);
        setFormValidationMessage(`An unknown error occurred.  Please contact support.`);
      }
      setSubmitted(false);
      return;
    }

    await new Promise((r) => setTimeout(r, 3000));
    props.getStacks();
    await new Promise((r) => setTimeout(r, 2000));
    setRedirectToStack(true);
    setSubmitted(false);
  };

  const handleInputChange = async (e) => {
    let inputName = e.target.name;
    let value = e.target.value;

    console.log(e);
    console.log(e.target);

    if (inputName === "serverNameInput") {
      inputName = "serverName";
      value = slugify(value, {
        lower: true,
        strict: true
      });
      setServerName(value);
    } else if (inputName === "region") {
      setRegion(value);
    } else if (inputName === "experimental") {
      if (e.target.checked) {
        setPendingExperimentalState(true);
        setShowExperimentalModal(true);
      } else {
        setExperimentalEnabled(false);
        // If disabling experimental and v13 is selected, reset to v12
        if (foundryVersion === "v13") {
          setFoundryVersion("v12");
        }
      }
    } else {
      setFoundryVersion(value);
    }
  };

  const handleExperimentalConfirm = () => {
    setExperimentalEnabled(true);
    setShowExperimentalModal(false);
  };

  const handleExperimentalDecline = () => {
    setExperimentalEnabled(false);
    setPendingExperimentalState(false);
    setShowExperimentalModal(false);
  };

  const renderSubmitButton = () => {
    const buttonProps = { disabled: submitted };

    let buttonContent = "Submit";
    let result = null;

    if (submitted) {
      buttonContent = <Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" />;
    }

    const button = (
      <Button variant="info" type="submit" onClick={handleNewStackSubmit} {...buttonProps}>
        {buttonContent}
      </Button>
    );

    return button;
  };

  return (
    <>
      {props.stacks.length <= props.user.subscription.plan.stacks ||
      props.user.storage.PK === "user#google-oauth2|100954083233413863078" ? null : (
        <Redirect to={`/`} />
      )}
      {redirectToStack ? <Redirect to={`/server/${serverName}`} /> : null}
      <Modal show={showExperimentalModal} onHide={handleExperimentalDecline}>
        <Modal.Header closeButton>
          <Modal.Title>Experimental Version Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="warning">
            I acknowledge that experimental versions of Foundry are not intended for general use. As such support is not
            provided for experimental versions.
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleExperimentalDecline}>
            Decline
          </Button>
          <Button variant="primary" onClick={handleExperimentalConfirm}>
            Accept
          </Button>
        </Modal.Footer>
      </Modal>
      <Form className="container-fluid px-5">
        <Form.Group as={Row} className="justify-content-center">
          <Col lg={10}>
            <Row className="mb-3">
              <Form.Label column lg={4}>
                Foundry Version
              </Form.Label>
              <Col lg={8}>
                <FoundryVersionPickerComponent
                  selectedVersion={foundryVersion}
                  changeHandler={handleInputChange}
                  experimentalEnabled={experimentalEnabled}
                />
              </Col>
            </Row>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg={4}>
                Server Name
              </Form.Label>
              <Col lg={8}>
                <InputGroup>
                  <Form.Control
                    name="serverNameInput"
                    type="text"
                    placeholder="Enter server name"
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg={4}>
                Region
              </Form.Label>
              <Col lg={8}>
                <InputGroup>
                  <Form.Control as="select" name="region" onChange={handleInputChange}>
                    <option value="us-east-2" selected>
                      US East 2 (Ohio)
                    </option>
                    <option value="eu-central-1">EU Central (Frankfurt)</option>
                    <option value="ap-southeast-2">South-East Asia Pacific (Sydney)</option>
                    <option value="us-west-2">US West (Oregon)</option>
                    <option value="sa-east-1">South America (Sao Paulo)</option>
                  </Form.Control>
                </InputGroup>
              </Col>
            </Form.Group>

            <InputGroup className="mb-3">
              <Form.Control placeholder={serverName} disabled />
              <InputGroup.Append>
                <InputGroup.Text id="basic-addon2">.moltenhosting.com</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>

            <Form.Text className="text-muted mb-3">
              Server names must start with a letter, be globally unique, and between 4 and 30 characters
            </Form.Text>

            {formValidationErrors ? (
              <Alert variant="danger" onClose={() => setFormValidationErrors(false)} dismissible className="mb-3">
                {formValidationMessage}
              </Alert>
            ) : null}

            <div className="mb-3">{renderSubmitButton()}</div>

            <Row>
              <Col lg={10}>
                <Form.Check
                  type="checkbox"
                  id="experimental-check"
                  name="experimental"
                  label="Enable experimental versions? (Required For Ember)"
                  onChange={handleInputChange}
                  checked={experimentalEnabled}
                  className="mt-2"
                />
              </Col>
            </Row>
          </Col>
        </Form.Group>
      </Form>
    </>
  );
}
