import React from "react";
import Iframe from "@trendmicro/react-iframe";
import env from "../env";
import { useAuth0 } from "@auth0/auth0-react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export default function TermsComponent(props) {
  const { getAccessTokenSilently } = useAuth0();

  const handleTermsAccept = async () => {
    const accessToken = await getAccessTokenSilently({ audience: env.AUD });

    await fetch(env.API_URL + "/api/user", {
      audience: env.AUD,
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      }
    });

    props.getUser();
  };

  return (
    <Modal.Dialog size="lg">
      <Modal.Body color="white">
        <Iframe src="terms-and-conditions.html" width="100%" height="600" />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="success" onClick={handleTermsAccept}>
          Accept
        </Button>
      </Modal.Footer>
    </Modal.Dialog>
  );
}
