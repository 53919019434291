import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Jumbotron from "react-bootstrap/Jumbotron";
import TermsComponent from "../shared/termsComponent";

const renderIndex = () => {
  return (
    <div>
      <Row>
        <Col xs={6}>
          <Jumbotron style={{ height: "140vh" }}>
            <h3>FAQ</h3>
            <hr></hr>
            <br />
            <ul className="text-left faq">
              <li>
                <h5>Why does my server keep shutting down?</h5>
              </li>
              Servers are put to sleep after a 1/2/3 hours of inactivity (depending on subscription plan). Unfortunately
              not all activity is measurable by our monitors, and so we support{" "}
              <a href="https://molten-hosting.notion.site/Discord-Webhooks-c4f49b98ff524bb596073d307e52ed0e">
                Discord webhooks
              </a>{" "}
              to help notify users before their server is set to be shut down.
              <li>
                <h5>How do I upgrade my account?</h5>
              </li>
              You can upgrade your account in the upper right hand corner underneath the My Account dropdown. You can
              also reach out to <a href="mailto:support@moltenhosting.com">support@moltenhosting.com</a> or the Discord
              and ask to be upgraded.
              <li className="mt-3">
                <h5>Why is my server asking for a password?</h5>
              </li>
              We set a default password when we create your server. This protects it until you're ready to set it up for
              yourself. You can find the default password by viewing your server details.
              <li className="mt-3">
                <h5>Can I change my default password?</h5>
              </li>
              Yes you can! You can set it via the FoundryVTT configuration menu on your server. If you have lost your
              password, please submit a support ticket and we can help you reset it.
              <li className="mt-3">
                <h5>How do I upload/manage/delete my files?</h5>
              </li>
              Head over to your server details page for the connection details. We provide step by step instructions in
              our <a href="https://www.notion.so/Managing-Server-Files-df96be0dcde74775994a86c844ba1a4e">user guide</a>.
              <li className="mt-3">
                <h5>How do I let my players wake my server?</h5>
              </li>
              We provide a "magic URL" in your server details that you can distribute to your players. When they visit
              this link, it will wake the server and take it to the setup page or default world.
              <li className="mt-3">
                <h5>
                  I imported my world via WEBDAV/CloudCommander, but I don't see anything or I'm getting a 502 error!
                  What gives?
                </h5>
              </li>
              The Foundry process needs a restart after you add worlds to the data directory. Click the "Restart
              Foundry" button in your server detail page. That will restart Foundry but leave your server running.
            </ul>
          </Jumbotron>
        </Col>
        <Col xs={6}>
          <Jumbotron style={{ height: "140vh" }}>
            <h3>News</h3>
            <hr></hr>
            <h4 style={{ color: "#FD4A19" }}>March 20th, 2025</h4>
            <p className="text-left">
              <br />
              Hey folks! A small update this week. First new things:
              <ol>
                <li>I've replaced CloudCommander with FileBrowser! Hopefully its more stable and easier to use.</li>
                <li>
                  Added a column to the backups on the server detail page that shows how long until the backup expires.
                </li>
                <li>
                  I have replaced the existing server export with something that I hope is faster and more stable for
                  users to use (especially those with large storage quotas).
                </li>
              </ol>
              <h4 style={{ color: "#FD4A19" }}>
                NOTICE: USER CREATED backups available to ELITE users will be automatically removed after six months
                starting on <u>March 31st</u>.
              </h4>
              <br />
              As always, if you have any questions or need assistance, don't hesitate to reach out to me via Discord or
              email!
              <p style={{ color: "#FD4A19", paddingLeft: 30 }}> - Mike (VersaceHovercraft)</p>
            </p>
            <hr></hr>
            <br />
            <h4 style={{ color: "#FD4A19" }}>March 10, 2025</h4>
            <p className="text-left">
              <br />
              I'm excited to announce two major updates to MoltenHosting today! First, you can now upgrade your server
              resources directly through the <a href="/server-resources">Server Resources</a> page. This gives you more
              control over your server's performance and allows you to scale resources up or down as needed. You can
              select between two upgraded tiers (and more to come):
              <table className="table table-bordered mt-2 mb-2">
                <thead>
                  <tr>
                    <th>Tier</th>
                    <th>vCPU</th>
                    <th>RAM</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Base</td>
                    <td>2 vCPUs</td>
                    <td>1 GB</td>
                  </tr>
                  <tr>
                    <td>Tier 1</td>
                    <td>2 vCPUs</td>
                    <td>2 GB</td>
                  </tr>
                  <tr>
                    <td>Tier 2</td>
                    <td>2 vCPUs</td>
                    <td>4 GB</td>
                  </tr>
                </tbody>
              </table>
              <br />
              It should be noted that the base tier is the default tier and is included in all plans and is sufficient
              for most FoundryVTT worlds. However, if you are running large or long running campaigns and have begun to
              notice sluggishness or long loading times, consider upgrading.
              <br />
              <hr />
              Second, we've added support for experimental versions of FoundryVTT! You can now create new servers with
              v13 experimental. I do not intend to enable experiemntal version upgrades from within FoundryVTT at this
              time. This feature will be particularly valuable as people begin experimenting with the{" "}
              <a href="https://foundryvtt.com/ember/">Ember Alpha</a>. You can now test your worlds and modules with
              development/test versions to ensure everything works smoothly when the new version officially launches.
              <br />
              <br />
              As always, if you have any questions or need assistance, don't hesitate to reach out to me via Discord or
              email!
              <p style={{ color: "#FD4A19", paddingLeft: 30 }}> - Mike (VersaceHovercraft)</p>
            </p>
          </Jumbotron>
        </Col>
      </Row>
    </div>
  );
};

export default function IndexComponent(props) {
  // Don't show anything while loading or if termsAccepted is null (not yet loaded)
  if (props.loading || !props.user || !props.user.settings || props.user.settings.termsAccepted === null) {
    return null;
  }

  return props.user.settings.termsAccepted ? (
    renderIndex()
  ) : (
    <TermsComponent user={props.user} getUser={props.getUser} />
  );
}
