import React from "react";
import Form from "react-bootstrap/Form";

export default function FoundryVersionPickerComponent(props) {
  let selectProps = {
    defaultValue: "v12"
  };

  if (props.selectedVersion !== undefined) {
    selectProps.defaultValue = props.selectedVersion;
  }

  if (props.changeHandler !== undefined) {
    selectProps.onChange = props.changeHandler;
  }

  return (
    <Form.Control as="select" name="foundryVersion" {...selectProps}>
      <option value="v10">v10</option>
      <option value="v11">v11</option>
      <option value="v12" selected>
        v12
      </option>
      <option value="v13" disabled={!props.experimentalEnabled}>
        v13 (experimental)
      </option>
    </Form.Control>
  );
}
