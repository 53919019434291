import React, { useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CardDeck from "react-bootstrap/CardDeck";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useAuth0 } from "@auth0/auth0-react";
import env from "../env";
import Alert from "react-bootstrap/esm/Alert";
import TermsComponent from "../shared/termsComponent";
import { Redirect } from "react-router-dom";

export default function Purchase(props) {
  const stripe = useStripe();

  const { getAccessTokenSilently } = useAuth0();

  const BASIC = "BASIC";
  const PRO = "PRO";
  const ELITE = "ELITE";

  const [selectedTier, setSelectedTier] = useState(null);
  const [selectedServerTier, setSelectedServerTier] = useState("BASE");
  const [licenseAck, setLicenseAck] = useState(false);

  const handleCheckout = async () => {
    const accessToken = await getAccessTokenSilently({ audience: env.AUD });

    const response = await fetch(env.API_URL + "/api/checkout", {
      audience: env.AUD,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        plan: selectedTier,
        serverTier: selectedServerTier
      })
    });

    const payload = await response.json();

    await stripe.redirectToCheckout({
      sessionId: payload.id
    });
  };

  return (
    <div>
      {props.user.subscription != null ? <Redirect to="/" /> : null}
      <Row hidden={props.user.settings.termsAccepted != null}>
        <Col></Col>
        <Col>
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </Col>
        <Col></Col>
      </Row>
      <Row hidden={props.user.settings.termsAccepted === null || props.user.settings.termsAccepted}>
        <Col></Col>
        <Col xs={10}>
          <TermsComponent user={props.user} getUser={props.getUser} />
        </Col>
        <Col></Col>
      </Row>
      <Row hidden={!props.user.settings.termsAccepted}>
        <Col></Col>
        <Col xs={10}>
          <CardDeck>
            <Card
              {...(selectedTier === BASIC ? { border: "warning" } : { style: { opacity: selectedTier ? 0.6 : 1 } })}
            >
              <Card.Header as="h4" style={{ background: "#FD4A19" }}>
                BASIC
              </Card.Header>
              <Card.Header as="h5">$4 / Month</Card.Header>
              <Card.Body>
                <Card.Text style={{ height: "80px" }}>
                  Ideal for getting started. Basic gives you everything you need to kickstart your campaign.
                </Card.Text>
                <ListGroup className="list-group-flush">
                  <hr className="my-2" />
                  <ListGroupItem>5GB Storage</ListGroupItem>
                  <ListGroupItem>Foundry VTT with Unlimited Worlds</ListGroupItem>
                  <ListGroupItem>File Manager Access</ListGroupItem>
                  <ListGroupItem>Weekly Backups</ListGroupItem>
                  <ListGroupItem>Email/Discord Support</ListGroupItem>
                  <ListGroupItem style={{ visibility: "hidden" }}>Spacer</ListGroupItem>
                  <ListGroupItem style={{ visibility: "hidden" }}>Spacer</ListGroupItem>
                </ListGroup>
              </Card.Body>
              <Card.Footer>
                <Button onClick={() => setSelectedTier(BASIC)}>Select</Button>
              </Card.Footer>
            </Card>
            <Card {...(selectedTier === PRO ? { border: "warning" } : { style: { opacity: selectedTier ? 0.6 : 1 } })}>
              <Card.Header as="h4" style={{ background: "#FD4A19" }}>
                PRO
              </Card.Header>
              <Card.Header as="h5">$8 / Month</Card.Header>
              <Card.Body>
                <Card.Text style={{ height: "80px" }}>
                  For the DM on the go. Perfect for the FoundryVTT campaign in progress, or starting a new campaign in
                  the cloud.
                </Card.Text>
                <ListGroup className="list-group-flush">
                  <hr className="my-2" />
                  <ListGroupItem>10GB Storage</ListGroupItem>
                  <ListGroupItem>Everything in the Basic tier!</ListGroupItem>
                  <ListGroupItem>Nightly Backups</ListGroupItem>
                  <ListGroupItem>Server Start/Stop Notifications</ListGroupItem>
                  <ListGroupItem style={{ visibility: "hidden" }}>Spacer</ListGroupItem>
                  <ListGroupItem style={{ visibility: "hidden" }}>Spacer</ListGroupItem>
                  <ListGroupItem style={{ visibility: "hidden" }}>Spacer</ListGroupItem>
                </ListGroup>
              </Card.Body>
              <Card.Footer>
                <Button onClick={() => setSelectedTier(PRO)}>Select</Button>
              </Card.Footer>
            </Card>
            <Card
              {...(selectedTier === ELITE ? { border: "warning" } : { style: { opacity: selectedTier ? 0.6 : 1 } })}
            >
              <Card.Header as="h4" style={{ background: "#FD4A19" }}>
                ELITE
              </Card.Header>
              <Card.Header as="h5">$12 / Month</Card.Header>
              <Card.Body>
                <Card.Text style={{ height: "80px" }}>
                  For the omega-powerful DM. Elite gives you the quality of life improvements to ensure your campaign is
                  on the bleeding edge and always ready to go.
                </Card.Text>
                <ListGroup className="list-group-flush">
                  <hr className="my-2" />
                  <ListGroupItem>20GB Storage</ListGroupItem>
                  <ListGroupItem>Everything in the Basic and Pro tier!</ListGroupItem>
                  <ListGroupItem>Second Server Slot For Testing/Staging</ListGroupItem>
                  <ListGroupItem>On Demand Backups</ListGroupItem>
                  <ListGroupItem style={{ visibility: "hidden" }}>Spacer</ListGroupItem>
                  <ListGroupItem style={{ visibility: "hidden" }}>Spacer</ListGroupItem>
                  <ListGroupItem style={{ visibility: "hidden" }}>Spacer</ListGroupItem>
                </ListGroup>
              </Card.Body>
              <Card.Footer>
                <Button onClick={() => setSelectedTier(ELITE)}>Select</Button>
              </Card.Footer>
            </Card>
          </CardDeck>
        </Col>
        <Col></Col>
      </Row>
      <Row hidden={!props.user.settings.termsAccepted}>
        <Col></Col>
        <Col xs={10}>
          <Row className="justify-content-center">
            <Col md={8}>
              <Card className="mt-4">
                <Card.Header>Server Resources</Card.Header>
                <Card.Body>
                  <Alert variant="warning">
                    If you have a long running campaign the basic servers may not be sufficient, you can{" "}
                    <strong>optionally</strong> increase the resources of the server.
                  </Alert>
                  <Alert variant="danger">
                    For <strong>EMBER</strong> it is <strong>strongly recommended</strong> to upgrade to Tier 2 to have
                    the best possible experience.
                  </Alert>
                  <Form>
                    <Form.Group>
                      <Form.Control
                        as="select"
                        value={selectedServerTier}
                        onChange={(e) => setSelectedServerTier(e.target.value)}
                      >
                        <option value="BASE">BASE - 1vCPU, 1GB of RAM</option>
                        <option value="TIER1">Tier 1 - 1vCPU, 2GB of RAM (+$4/mo)</option>
                        <option value="TIER2">Tier 2 - 1vCPU, 4GB of RAM (+$8/mo)</option>
                      </Form.Control>
                    </Form.Group>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col></Col>
      </Row>
      <br />
      <Row hidden={!props.user.settings.termsAccepted}>
        <Col></Col>
        <Col xs={10}>
          <Row className="justify-content-center">
            <Col md={8}>
              <Card>
                <Card.Header>
                  <Alert variant="warning">
                    <Form>
                      <Form.Check
                        onChange={(e) => {
                          setLicenseAck(e.target.checked);
                        }}
                        type="checkbox"
                        label="I affirm that I possess a valid license for FoundryVTT, and that Molten Hosting will not supply a
                    license for FoundryVTT."
                      />
                    </Form>
                  </Alert>
                  <Button {...{ disabled: selectedTier === null || !licenseAck }} onClick={handleCheckout}>
                    Checkout
                  </Button>
                </Card.Header>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col></Col>
      </Row>
      <br />
    </div>
  );
}
