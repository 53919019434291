import React, { useState, useEffect } from "react";
import "./App.css";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import { Router, Switch, Route, Link } from "react-router-dom";
import { createBrowserHistory } from "history";
import Lobby from "./lobby/lobby";
import Management from "./management/management";
import { useAuth0 } from "@auth0/auth0-react";
import env from "./env";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCopy, faUserCircle, faBook, faServer, faQuestion, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { faDiscord, faStripeS, faCcStripe } from "@fortawesome/free-brands-svg-icons";
import { FaCcStripe, FaCrown, FaChessKnight } from "react-icons/fa";
import { useApi } from "./management/useApi";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dataonly from "./dataonly/Dataonly";
import { ImCloudUpload } from "react-icons/im";
import TermsComponent from "./shared/termsComponent";

library.add([faCopy, faDiscord, faBook, faServer, faQuestion, faUserCircle, faSignOutAlt, faStripeS, faCcStripe]);

export const history = createBrowserHistory();

export default function App(props) {
  const [error, setError] = useState(null);
  const [warning, setWarning] = useState(null);
  const [userLoading, setUserLoading] = useState(false);
  const [upgradePlan, setUpgradePlan] = useState("");
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const handleClose = () => setShowUpgradeModal(false);

  const showProUpgrade = () => {
    setUpgradePlan("PRO");
    setShowUpgradeModal(true);
  };

  const showEliteUpgrade = () => {
    setUpgradePlan("ELITE");
    setShowUpgradeModal(true);
  };

  const { logout, isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
  const [user, setUser] = useState({
    settings: { termsAccepted: null },
    subscription: { plan: { stacks: 1 } },
    stripeCustomerId: null,
    storage: null
  });

  const { loading, stackError, refresh, data = [] } = useApi(env.API_URL + "api/servers", { audience: env.AUD }, []);

  const handleStripePortalClick = async () => {
    const accessToken = await getAccessTokenSilently({ audience: env.AUD });

    fetch(env.API_URL + "api/billing", {
      audience: env.AUD,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        window.location.href = data.url;
      });
  };

  const handleUpgradeClick = async (plan) => {
    const accessToken = await getAccessTokenSilently({ audience: env.AUD });

    fetch(env.API_URL + "api/upgrade", {
      audience: env.AUD,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        plan: plan
      })
    }).then((reponse) => {
      getUser();
    });
  };

  const getUser = async () => {
    const accessToken = await getAccessTokenSilently({ audience: env.AUD });

    let response = null;
    fetch(env.API_URL + "api/user", {
      audience: env.AUD,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.storage && data.storage.lastUpdated) {
          data.storage.lastUpdated = moment.utc(data.storage.lastUpdated * 1000).local();
        }
        setUser(data);
        setUserLoading(false);
      });

    return response;
  };

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      getUser();
    }

    let timer = setInterval(() => {
      refresh();
    }, 30000);

    if (error != null) {
      setError(error.error);
    }

    return function cleanup() {
      clearInterval(timer);
    };
  }, [isLoading, loading]);

  if (data) {
    data.map((stack, index) => {
      stack.backups.map((backup, index) => {
        backup.startTime = moment.utc(backup.startTime).local();
        if (backup.migratedStartTime != null) {
          backup.migratedStartTime = moment.utc(backup.migratedStartTime).local();
        }
      });

      if (stack.dataVolume.lastUpdated != null) {
        stack.dataVolume.lastUpdated = moment.utc(stack.dataVolume.lastUpdated).local();
      }
    });
  }

  return (
    <div className="App">
      <Router history={history}>
        <Navbar style={{ background: "rgb(18,20,29)" }} expand="lg">
          <img alt="" src="/molten-logo-small.png" width="30" height="30" className="d-inline-block align-top" />{" "}
          <Navbar.Brand href="/" style={{ color: "#FD4A19" }}>
            Molten Hosting
          </Navbar.Brand>
          <Nav.Link
            style={{ color: "#FD4A19" }}
            href="https://molten-hosting.notion.site/molten-hosting/Molten-Wiki-e953ab3ce6f949029e9f237cab84d2da"
          >
            <FontAwesomeIcon icon="book" className="mr-2" />
            User Guides
          </Nav.Link>
          <Nav.Link style={{ color: "#FD4A19" }} href="https://discord.gg/pzYXEQ3">
            <FontAwesomeIcon className="mr-2" icon={["fab", "discord"]} />
            Discord/Support
          </Nav.Link>
          {isAuthenticated && user.subscription !== null && (
            <NavDropdown
              title={
                <>
                  <FontAwesomeIcon icon="server" className="mr-2" />
                  Servers
                  {(loading || userLoading) && (
                    <Spinner animation="border" role="status" size="sm" className="ml-2">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  )}
                </>
              }
            >
              {!loading && !userLoading && data && (
                <>
                  {data.map((stack, index) => (
                    <>
                      <NavDropdown.Divider hidden={index === 0} />
                      <NavDropdown.Item key={stack.stackName} href={`/server/${stack.stackName}`}>
                        {stack.stackName}
                      </NavDropdown.Item>
                    </>
                  ))}
                  {data.length < user.subscription.plan.stacks ||
                  (user.storage && user.storage.PK === "user#google-oauth2|100954083233413863078") ? (
                    <>
                      <NavDropdown.Divider hidden={data.length === 0} />
                      <NavDropdown.Item
                        key="newServerLink"
                        href={`/new`}
                        style={{ backgroundColor: "#FD4A19", color: "white" }}
                      >
                        Create New Server
                      </NavDropdown.Item>
                    </>
                  ) : null}
                </>
              )}
              {(loading || userLoading) && (
                <NavDropdown.Item>
                  <Spinner animation="border" role="status" size="sm">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </NavDropdown.Item>
              )}
            </NavDropdown>
          )}
          {user.subscription === null ? null : (
            <NavDropdown
              className="ml-auto"
              title={
                <>
                  <FontAwesomeIcon icon="user-circle" className="mr-2" />
                  My Account ({user.subscription.plan.name})
                </>
              }
            >
              <NavDropdown.Item
                onClick={() => handleStripePortalClick()}
                hidden={!isAuthenticated || !user.stripeCustomerId}
              >
                <FaCcStripe /> {"   "} Billing
              </NavDropdown.Item>
              <NavDropdown.Item key="resourcesLink" href={`/storage`}>
                <ImCloudUpload /> {"   "} Upgrade Resources
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => showProUpgrade(true)}
                hidden={
                  !isAuthenticated || user.subscription.plan.name === "PRO" || user.subscription.plan.name === "ELITE"
                }
              >
                <FaChessKnight /> {"   "} Upgrade To PRO ($8/mo)
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => showEliteUpgrade(true)}
                hidden={!isAuthenticated || user.subscription.plan.name === "ELITE"}
              >
                <FaCrown /> {"   "} Upgrade To ELITE ($12/mo)
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => navigator.clipboard.writeText(user.storage.PK)}
                hidden={!isAuthenticated || !user.stripeCustomerId || !user.storage}
              >
                <FontAwesomeIcon icon="copy" className="mr-2" /> User ID (click to copy)
              </NavDropdown.Item>
            </NavDropdown>
          )}
          <Nav.Link
            onClick={() => logout({ returnTo: window.location.origin })}
            style={{ color: "#FD4A19" }}
            hidden={!isAuthenticated}
          >
            <FontAwesomeIcon className="mr-2" icon="sign-out-alt" />
            Logout
          </Nav.Link>
        </Navbar>
        {error != null ? <Alert variant="danger"> Error occurred: {error} </Alert> : null}
        {warning != null ? <Alert variant="warning"> {warning} </Alert> : null}
        <Container fluid>
          <br />
          <Switch>
            <Route path="/lobby">
              <Lobby errorHandler={setError} />
            </Route>
            <Route path="/">
              {user.subscription != null && user.subscription.subscriptionStatus === "DATA_ONLY" ? (
                <Dataonly user={user} stacks={data} />
              ) : (
                <Management
                  warningHandler={setWarning}
                  errorHandler={setError}
                  user={user}
                  getUser={getUser}
                  loading={userLoading}
                  stacks={data}
                  getStacks={refresh}
                  stacksLoading={loading}
                />
              )}
            </Route>
          </Switch>
        </Container>
      </Router>
      <Navbar style={{ background: "rgb(18,20,29)" }} expand="sm" className="footer">
        <Nav.Link href="https://moltenhosting.com/terms.html">Terms and Conditions</Nav.Link>
      </Navbar>
      <Modal show={showUpgradeModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upgrade to {upgradePlan}</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you'd like to upgrade to {upgradePlan}?</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="success" onClick={() => handleUpgradeClick(upgradePlan)}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
